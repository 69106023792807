/* Fonts */
@font-face {
	font-family: 'Centivo-Regular';
	src: url('./assets/fonts/centivo-fonts/Regular-Regular.woff');
}

@font-face {
	font-family: 'Centivo-Medium';
	src: url('./assets/fonts/centivo-fonts/Regular-Medium.ttf');
}

@font-face {
	font-family: 'Centivo-SemiBold';
	src: url('./assets/fonts/centivo-fonts/Regular-SemiBold.ttf');
}

@font-face {
	font-family: 'Centivo-Bold';
	src: url('./assets/fonts/centivo-fonts/Regular-Bold.ttf');
}

body {
  background-color: #f5f7e5;
  margin: 0;
  font-family: 'Centivo-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000000;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
  border: 0;
  background-color: inherit;
}

a:hover {
  text-decoration: underline;
  border: 0;
}

table {
  border-collapse: collapse;
  border: 1px solid rgba(149,152,154,1);
}

th, td {
  padding: 5px 15px;
  text-align: left;
  border-bottom: 1px solid rgba(149,152,154,1);
}

th {
  background-color: rgba(149,152,154,1);
  font-family: 'Centivo-Bold', sans-serif;
  padding: 10px 15px;
}

tfoot {
  font-family: 'Centivo-Bold', sans-serif;
  color: rgba(68, 68, 68, 1);
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 10px;
}

.centered-notice {
  text-align: center;
}

.banner {
  min-width: 100%;
  padding: 15px 0;
  /* background-color: #e85d35; */
  text-align: center;
}